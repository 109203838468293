import React from "react";

export function H1({ children, as = "h1", alignCenter = false, ...props }) {
  return (
    <>
      {as === "h1" && <h1 {...props}>{children}</h1>}
      {as === "h2" && <h2 {...props}>{children}</h2>}
      <style jsx>{`
        h1,
        h2 {
          font-size: 48px;
          text-align: center;
          margin: 0;
          margin-bottom: 12px;
          font-family: "Open Sans", sans-serif;
          font-weight: inherit;
        }
        @media (max-width: 830px) {
          h1,
          h2 {
            font-size: 32px;
          }
        }
        @media (max-width: 580px) {
          h1,
          h2 {
            font-size: 24px;
            text-align: ${alignCenter ? "center" : "left"};
            font-weight: 700;
          }
        }
      `}</style>
    </>
  );
}
