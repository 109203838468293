import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { H1 } from "../components/Typography";
import Underline from "../components/Underline";

export default function FAQPage({ data }) {
  const { markdownRemark: faq } = data;

  return (
    <Layout whiteHeader>
      <section>
        <div className="container">
          <H1>Frequently Asked Questions</H1>
          <Underline />
          {JSON.stringify(faq, null, 2)}
        </div>
        <style jsx>{`
          section {
            padding: 32px;
          }
          .container {
            max-width: 1278px;
            margin: 0 auto;
          }
        `}</style>
      </section>
    </Layout>
  );
}

export const faqQuery = graphql`
  query FaqByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        answer
        category
        question
        date
      }
      fields {
        frontmattermd {
          answer {
            html
          }
        }
      }
    }
  }
`;
